<template lang="html">
  <div class="card card-primary card-outline">
    <div class="card-header">
      <h3 class="card-title">修改资料</h3>
      <div class="card-tools mr-0">
      </div>
    </div>

    <!-- /.box-header -->
    <div class="card-body">
      <div class="row">
        <div class="col-sm-6 col-md-5 col-lg-5 col-xl-4" v-if="user">
          <div class="form-group">
            <label for="userName">用户名</label>
            <input type="text" class="form-control" :class="{ 'is-invalid': $v.user.userName.$error }" id="userName" v-model.trim="$v.user.userName.$model" :readonly="user.userNameChanged" placeholder="用户名">
            <div class="invalid-feedback">
              <span v-if="!$v.user.userName.required">请输入用户名</span>
              <span v-if="!$v.user.userName.minLength">用户名至少 {{$v.user.userName.$params.minLength.min}} 个字符</span>
              <span v-if="!$v.user.userName.maxLength">用户名最多 {{$v.user.userName.$params.maxLength.max}} 个字符</span>
              <span v-if="!$v.user.userName.isUnique">用户名不可用</span>
            </div>
          </div>
          <div class="form-group">
            <label for="nickName">昵称</label>
            <input type="text" class="form-control" :class="{ 'is-invalid': $v.user.nickName.$error }" id="nickName" v-model.trim="$v.user.nickName.$model" placeholder="昵称">
            <div class="invalid-feedback">
              <span v-if="!$v.user.nickName.required">请输入昵称</span>
              <span v-if="!$v.user.nickName.minLength">昵称至少 {{$v.user.nickName.$params.minLength.min}} 个字符</span>
              <span v-if="!$v.user.nickName.maxLength">昵称最多 {{$v.user.nickName.$params.maxLength.max}} 个字符</span>
            </div>
          </div>
          <div class="form-group">
            <label for="fullName">姓名</label>
            <input type="text" class="form-control" :class="{ 'is-invalid': $v.user.fullName.$error }" id="fullName" v-model.trim="$v.user.fullName.$model" placeholder="姓名">
            <div class="invalid-feedback">
              <span v-if="!$v.user.fullName.required">请输入姓名</span>
              <span v-if="!$v.user.fullName.minLength">姓名至少 {{$v.user.fullName.$params.minLength.min}} 个字符</span>
              <span v-if="!$v.user.fullName.maxLength">姓名最多 {{$v.user.fullName.$params.maxLength.max}} 个字符</span>
            </div>
          </div>
          <div class="form-group">
            <label for="Gender">性别</label>
            <div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="Gender" id="genderless" :value="null" v-model="user.gender">
                <label class="form-check-label" for="genderless">保密</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="Gender" id="male" :value="true" v-model="user.gender">
                <label class="form-check-label" for="male">男</label>
              </div>
              <div class="form-check form-check-inline">
                <input class="form-check-input" type="radio" name="Gender" id="female" :value="false" v-model="user.gender">
                <label class="form-check-label" for="female">女</label>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="email">Email</label>
            <div class="input-group">
              <input type="email" class="form-control" :class="{ 'is-invalid': $v.user.email.$error }" id="email" v-model.trim="$v.user.email.$model" placeholder="Email" :readonly="user.emailConfirmed">
              <div v-if="!user.emailConfirmed" class="input-group-prepend">
                <button v-if="!emailSended" class="btn btn-primary" @click="sendVerifyEmail()">验证</button>
                <button v-else class="btn btn-primary" disabled="disabled">邮件已发送</button>
              </div>
              <div class="invalid-feedback">
                <span v-if="!$v.user.email.required">请输入 Email</span>
                <span v-if="!$v.user.email.email">无效的 Email 格式</span>
                <span v-if="!$v.user.email.isUnique">此邮件已经被其它账户绑定</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="phoneNumber">手机号码</label>
            <div class="input-group">
              <input type="tel" class="form-control" :class="{ 'is-invalid': $v.user.phoneNumber.$error }" id="phoneNumber" v-model.trim="$v.user.phoneNumber.$model" placeholder="手机号码" :readonly="user.phoneNumberConfirmed" maxlength="11">
              <div v-if="!user.phoneNumberConfirmed" class="input-group-prepend">
                <router-link class="btn btn-primary" :to="{ name: 'ConfirmPhoneNumber'}">验证</router-link>
              </div>
              <div class="invalid-feedback">
                <span v-if="!$v.user.phoneNumber.required">请输入手机号码</span>
                <span v-if="!$v.user.phoneNumber.isMobile">无效的手机号码格式</span>
                <span v-if="!$v.user.phoneNumber.isUnique">此号码已经被其它账户绑定</span>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="summary">个人介绍</label>
            <!--<textarea type="text" class="form-control" id="summary" v-model:value="user.summary" placeholder="个人介绍"></textarea>-->
            <PellEditor v-model.trim="user.summary"
                        :options="options"
                        :buttons="['bold','italic','underline','strikethrough','sub','sup','olist','ulist','removeFormat',]" />
          </div>
        </div>
      </div>
    </div>

    <!-- /.box-body -->

    <div class="overlay" v-if="updateing">
      <i class="fas fa-spinner fa-pulse"></i>
    </div>
    <!-- end loading -->
    <Footer>
      <button type="button" class="btn btn-primary" @click="save" :disabled="updateing">保存</button>
    </Footer>

  </div>
</template>

<script>
  import { PellEditor, Footer } from 'common'
  import { required, minLength, maxLength, email, helpers } from 'vuelidate/lib/validators'
  //import PellEditor from '@/components/PellEditor.vue'
  //import Footer from '@/components/Footer.vue'
  const isMobile = helpers.regex('isMobile', /^1(3|4|5|6|7|8|9)\d{9}$/)
  const isTel = helpers.regex('isTel', /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{8}$/)
  const isUrl = helpers.regex('isUrl', /^((http:\/\/)|(https:\/\/))?([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.?)+(([a-zA-Z]{2,6})|(:[0-9]{1,5}))$/)

  export default {
    components: {
      PellEditor,
      Footer
    },
    data() {
      return {
        user: null,
        emailSended: false,
        updateing: false,
        options: {
          whiteList: {
            p: [],
            b: [],
            strong: [],
            i: [],
            u: [],
            strike: [],
            br: [],
            //h1: [],
            //h2: [],
            //blockquote: [],
            //hr: [],
            ol: [],
            ul: [],
            li: [],
            //code: [],
            //a: ['href'],
            //img: ['src'],
            sub: [],
            sup: [],
          },
          stripIgnoreTag: true,      // 过滤所有非白名单标签的HTML
          stripIgnoreTagBody: ['script'] // script标签较特殊，需要过滤标签中间的内容
        }
      }
    },
    validations: {
      user: {
        userName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(30),
          isUnique(value) {
            // standalone validator ideally should not assume a fields is required
            if (value === '') return true
            // simulate async call, fail for all logins with even length
            return new Promise((resolve, reject) => {
              this.$axios.get(`/Api/My/Users/CheckUserName/${encodeURI(value)}`)
                .then(function (response) {
                  //console.log(response)
                  resolve(response.data)
                }).catch(function (error) {
                  console.error(error)
                })
            })
          }
        },
        nickName: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(30),
        },
        fullName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(10),
        },
        email: {
          //required,
          email,
          isUnique(value) {
            // standalone validator ideally should not assume a fields is required
            if (value === '') return true
            // simulate async call, fail for all logins with even length
            return new Promise((resolve, reject) => {
              this.$axios.get(`/Api/My/Users/CheckEmail/${encodeURI(value)}`)
                .then(function (response) {
                  //console.log(response)
                  resolve(response.data)
                }).catch(function (error) {
                  console.error(error)
                })
            })
          }
        },
        phoneNumber: {
          required,
          isMobile,
          isUnique(value) {
            // standalone validator ideally should not assume a fields is required
            if (value === '') return true
            // simulate async call, fail for all logins with even length
            return new Promise((resolve, reject) => {
              this.$axios.get(`/Api/My/Users/CheckPhoneNumber/${encodeURI(value)}`)
                .then(function (response) {
                  //console.log(response)
                  resolve(response.data)
                }).catch(function (error) {
                  console.error(error)
                })
            })
          }
        },
      }
    },
    created() {
      this.loadData()
    },
    methods: {
      loadData() {
        let _this = this
        var url = "/Api/My/Users"
        var params = {
        }

        this.$axios.get(url, { params: params })
          .then(function (response) {
            console.log(response)
            _this.user = response.data
          }).catch(function (error) {
            console.log(error)
          })

      },
      reset() {
        let _this = this
        _this.user = null
      },
      save() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        let _this = this
        _this.updateing = true;
        this.$axios.put("/Api/My/Users", _this.user)
          .then(function (response) {
            _this.$message({
              message: '保存成功',
              type: 'info',
              zIndex: 9999,
            })
            _this.loadData()
            _this.updateing = false;
          })
          .catch(function (error) {
            console.log(error)
            _this.updateing = false;
          })
      },
      sendVerifyEmail() {
        this.$v.user.email.$touch()
        if (this.$v.user.email.$invalid) {
          return
        }
        const _this = this
        this.$axios.post('/Api/My/Users/SendVerifyEmail', { email: this.user.email }).then((res) => {
          _this.emailSended = true
        }).catch((err) => {

        })
      },

    },
  }
</script>

<style lang="css">
  .top_container {
    width: 100%;
    position: relative;
    display: inline-block;
    max-width: 300px;
  }

  .dummy {
    margin-top: 100%;
  }

  .cropper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
</style>
